import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Method, Service } from 'shared-components/configuration';
import useSWComm from 'shared-components/hooks/useSWComm';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useServiceWorkerStatus } from 'shared-components/providers/ServiceWorkerStatusProvider';
import WaitSpinner from '../WaitSpinner';
import { usePrevious } from 'shared-components/hooks/usePrevious';

import { BankID } from './component';

type PersonIDDiscoveryProps = {
  onSuccess?: (data: any) => void;
  onFail?: (data: any) => void;
};

let pidTimer: NodeJS.Timeout | undefined;

export const PersonIDDiscovery = ({ onSuccess, onFail }: PersonIDDiscoveryProps) => {
  const requestId = useRef<string>('');
  const [qrcode, setQrcode] = useState('');
  const [ast, setAst] = useState('');
  const [hasError, setError] = useState(false);
  const [counter, setCounter] = useState(0);
  const swStatus = useServiceWorkerStatus();
  const prevConnStatus = usePrevious(swStatus.authEidConnected);

  useSWComm({
    service: Service.AUTHEID,
    method: Method.CONNECT,
    onResponse: (data: any) => {
      if (prevConnStatus === true && data === false) {
        setError(true);
      }
    }
  });

  const handleEvent = useCallback(
    (data: any) => {
      if (data.api === 'bankid') {
        const incoming = data.data;

        if (incoming?.status === 'AUTO_TOKEN') {
          setCounter(0);
          requestId.current = incoming.request_id;
          setAst(incoming.auto_start_token);
        }
        if (incoming?.status === 'REQUEST_STATUS_UNSPECIFIED') {
          setError(true);
        }
        if (incoming?.request_id !== requestId.current) {
          return;
        }
        if (incoming?.bankid?.status === 'SUCCESS') {
          const bankid = incoming.bankid.bankid_reply;
          onSuccess?.call(undefined, bankid);
          return;
        }

        switch (incoming?.status) {
          case 'UPDATE_QR': {
            const qrCodeSplitted = incoming.qr.split('.');
            setCounter(Number.parseInt(qrCodeSplitted?.[2]));
            setQrcode(incoming.qr);
            break;
          }
          case 'SUCCESS': {
            const bankid = incoming.bankid.bankid_reply;
            onSuccess?.call(undefined, bankid);
            break;
          }

          case 'KYC_ERROR':
          case 'REQUEST_STATUS_UNSPECIFIED': {
            setError(true);
            break;
          }
        }
      }
    },
    [onSuccess]
  );

  useEffect(() => {
    if (hasError) {
      onFail?.call(undefined, {
        errorMsg: 'Personal ID discovery failed'
      });
    }
  }, [hasError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (counter === 29) {
      setError(true);
    }
  }, [counter, setError]);

  const auth = useSWQuery({
    service: Service.AUTHEID,
    method: Method.QUERY,
    returnObjectName: 'bankid',
    data: {
      api: 'bankid',
      method: 'bankid_init',
      args: {
        ip: '127.0.0.1'
      }
    },
    auto: false,
    onResponse: handleEvent
  });

  useEffect(() => {
    if (swStatus.authEidConnected === true) {
      auth.execute({ args: { ip: '' } });
    }
  }, [swStatus.authEidConnected]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkTimeout = useCallback(() => {
    if (swStatus.authEidConnected !== true) {
      setError(true);
    }
  }, [swStatus.authEidConnected]);

  useEffect(() => {
    if (prevConnStatus === undefined && swStatus.authEidConnected === undefined) {
      pidTimer = setTimeout(checkTimeout, 20_000);
    } else if (swStatus.authEidConnected === true) {
      clearTimeout(pidTimer);
    }
  }, [swStatus.authEidConnected, prevConnStatus, checkTimeout]);

  return (
    <>
      {swStatus.authEidConnected === true ? (
        <>
          {isMobile ? (
            ast.length > 0 ? (
              <BankID token={ast} type="ast" progress={0} />
            ) : (
              <center>
                <WaitSpinner></WaitSpinner>
              </center>
            )
          ) : (
            <BankID token={qrcode} type="qr" progress={0} />
          )}
        </>
      ) : (
        <WaitSpinner></WaitSpinner>
      )}
    </>
  );
};
