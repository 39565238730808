import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isAndroid, isMobile } from 'react-device-detect';

import Icon from 'shared-components/components/Icon';
import { BankID } from 'shared-components/components/BankID';
import { BankIDIcon } from 'shared-components/assets/icons';
import { useGateway } from 'providers/GatewayProvider';
import { usePrevious } from 'utils';

export const BankAuthenticate = () => {
  const coreData = useGateway();
  const [progress, setProgress] = useState(0);
  const prevToken = usePrevious(coreData.values.token);
  const url = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('sessionId', coreData.values.sessionId);
    return currentUrl.toString();
  }, [coreData.values.sessionId]);

  useEffect(() => {
    if (prevToken && prevToken.length > 0 && coreData.values.token.length === 0) {
      setProgress(1);
    }
  }, [prevToken, coreData.values.token]);

  if (coreData.values.token.length === 0 && coreData.values.bank === 'lfbank' && isMobile) {
    setTimeout(() => {
      window.location.assign('bankid:///');
    }, 100);
  }

  return coreData.values.token.length === 0 && coreData.values.bank === 'lfbank' ? (
    <>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <Icon icon={BankIDIcon} />
        <p style={{ marginBottom: 0, marginTop: 10 }}>Check notifications in BankID on your device</p>
        <Button
          className="w-100"
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: 18,
            fontWeight: '600',
            wordWrap: 'break-word'
          }}
          size="lg"
          href={`bankid:///`}
        >
          Launch BankID App
        </Button>
      </div>
    </>
  ) : (
    <>
      <center>
        <BankID
          token={coreData.values.token}
          type={coreData.values.authMode}
          progress={progress}
          redirect={isAndroid ? 'null' : url}
          onSetProgress={(value) => setProgress(value)}
        />
      </center>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <p style={{ marginBottom: 0, marginTop: 10 }}>Open BankID on your device by pressing the button</p>
      </div>
    </>
  );
};
