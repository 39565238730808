import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { isAndroid } from 'react-device-detect';

import { BankIDIcon, BankIDVector } from 'shared-components/assets/icons';
import Icon from '../Icon';
import WaitSpinner from '../WaitSpinner';

type BankIDProps = {
  token?: string;
  type?: string;
  redirect?: string;
  num?: number;
  buttonDescription?: string;
  progress?: number;
  onSetProgress?: (value: number) => void;
};

export const urlGenerator = () => {
  /*
  const isChromeOnAppleDevice = Boolean(/CriOS/.test(navigator.userAgent));
  const isFirefoxOnAppleDevice = Boolean(/FxiOS/.test(navigator.userAgent));
  const isOperaTouchOnAppleDevice = Boolean(/OPT/.test(navigator.userAgent));
  const isSafari = Boolean(
    /^(?=.*(iphone|ipad|ipod))(?=.*applewebkit)(?!.*(crios|fxios|opios|chrome|android)).*_/i.test(navigator.userAgent)
  );
*/
  if (isAndroid) {
    return '&redirect=null';
  }

  return '&redirect=null';
  /*
  if (isChromeOnAppleDevice) {
    return `&redirect=${encodeURIComponent('googlechrome://')}`;
  }
  if (isFirefoxOnAppleDevice) {
    return `&redirect=${encodeURIComponent('firefox://')}`;
  }
  if (isOperaTouchOnAppleDevice) {
    return `&redirect=${encodeURIComponent(window.location.href.replace('http', 'touch-http'))}`;
  }
  if (isSafari) {
    return `&redirect=${encodeURIComponent(window.location.href)}`;
  }
  return `&redirect=null`;
  */
};

export const BankID = ({ token, type, buttonDescription, redirect = 'null', progress, onSetProgress }: BankIDProps) => {
  if (progress === 0 && token !== undefined && token.length > 0) {
    switch (type) {
      case 'image':
      case 'qr': {
        return token.startsWith('data:') ? (
          <>
            <img src={token} alt="token" style={{ height: '256px', width: '256px' }} />
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Icon icon={BankIDIcon} />
            </div>
          </>
        ) : (
          <>
            <QRCode value={token} />
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Icon icon={BankIDIcon} />
            </div>
          </>
        );
      }
      case 'ast': {
        return (
          <>
            <Button
              className="w-100"
              style={{
                textAlign: 'center',
                color: 'white',
                fontSize: 18,
                fontWeight: '600',
                wordWrap: 'break-word'
              }}
              onClick={() => {
                onSetProgress?.call(undefined, 1);
              }}
              size="lg"
              href={`https://app.bankid.com/?autostarttoken=${token}&redirect=${encodeURIComponent(redirect)}`}
            >
              {progress === 0 ? (
                <>
                  <Icon icon={BankIDVector} /> {buttonDescription || 'Run bankid app'}
                </>
              ) : (
                <Spinner style={{ color: 'white' }} />
              )}
            </Button>
          </>
        );
      }
      default: {
        return <div>Unsupported token type</div>;
      }
    }
  }
  return (
    <center>
      <WaitSpinner></WaitSpinner>
    </center>
  );
};
