import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { isAndroid, isMobile } from 'react-device-detect';

import { useGateway } from 'providers/GatewayProvider';
import { BankID } from 'shared-components/components/BankID';
import BlueFrame from 'shared-components/components/BlueFrame';
import { usePrevious } from 'utils';
import { useSWCallbackSignEvent } from 'shared-components/providers/SWEventProvider';
import { SEvent } from 'utils/stateMachine';
import { environment } from 'configuration';
import { BankIDIcon } from 'shared-components/assets/icons';
import Icon from 'shared-components/components/Icon';

export interface BankProps {
  name: string;
  icon: string;
}

export const SignTransaction = ({ onSetProgress }: { onSetProgress?: (value: number) => void }) => {
  const {
    values: { token, authMode, amount, currency, recipientName, recipientIban, stateRef, operationId, bank, sessionId },
    setters: { setToken }
  } = useGateway();
  const [progress, setProgress] = useState(0);
  const [link, setLink] = useState('');
  const [buttonText, setButtonText] = useState('Pay');

  const url = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('sessionId', sessionId);
    return currentUrl.toString();
  }, [sessionId]);

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && prevToken.length > 0 && token.length === 0) {
      setProgress(1);
    } else {
      setProgress(0);
    }
  }, [prevToken, token]);

  useSWCallbackSignEvent((data: any) => {
    const signEventData = data.piSignState;
    if (data !== undefined && data?.operationId === operationId) {
      if (signEventData === undefined) {
        return;
      }
      if (signEventData.paymentAuthStatus == 'failed') {
        stateRef.send({ type: SEvent.FAIL });
      }

      if (signEventData.paymentAuthStatus == 'recipMissing') {
        stateRef.send({ type: SEvent.ADD_RECIPIENT });
      }

      if (signEventData.paymentAuthStatus === 'requestAgain') {
        setToken('');
        setButtonText('Pay');
        setProgress(0);
      }

      if (
        signEventData.paymentAuthStatus === 'authReqd' &&
        token !== signEventData.token &&
        signEventData.token.length > 0
      ) {
        setToken(signEventData.token);
        setProgress(0);
      }
      if (signEventData.paymentAuthStatus === 'authReqd' && signEventData.url) {
        setLink(signEventData.url);
      }
    }
  });
  if (token.length === 0 && bank === 'lfbank' && isMobile) {
    setTimeout(() => {
      window.location.assign('bankid:///');
    }, 1000);
  }

  return (
    <>
      <BlueFrame style={{ padding: '24px' }}>
        {token.length > 0 && (
          <>
            <Row style={{ marginBottom: '8px', fontSize: 18 }}>
              <Col>Amount to pay</Col>
            </Row>
            <Row style={{ marginBottom: '14px', fontWeight: 700, fontSize: 24 }}>
              <Col>
                {currency} {amount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              </Col>
            </Row>
            <Row style={{ marginBottom: '8px', fontSize: 18 }}>
              <Col>{recipientName}</Col>
            </Row>
            <Row style={{ marginBottom: '30px', fontSize: 12 }}>
              <Col>{recipientIban}</Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            {token.length === 0 && bank === 'lfbank' ? (
              <>
                <div style={{ textAlign: 'center', marginTop: 30 }}>
                  <Icon icon={BankIDIcon} />
                  <p style={{ marginBottom: 0, marginTop: 10 }}>Check notifications in BankID on your device</p>
                  <Button
                    className="w-100"
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      fontSize: 18,
                      fontWeight: '600',
                      wordWrap: 'break-word'
                    }}
                    size="lg"
                    href={`bankid:///`}
                  >
                    Launch BankID App
                  </Button>
                </div>
              </>
            ) : (
              <BankID
                token={token}
                type={authMode}
                buttonDescription={buttonText}
                progress={progress}
                redirect={isAndroid ? 'null' : url}
                onSetProgress={(value) => {
                  setProgress(value);
                  onSetProgress?.call(this, value);
                }}
              />
            )}
          </Col>
        </Row>
        {link.length > 0 && (environment === 'staging' || environment === 'dev') ? (
          <Row>
            <Col>
              <Button href={link} target="_blank">
                End staging payment
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </BlueFrame>
    </>
  );
};
